<template>
  <div class="box">
    <topMenu :index="4"></topMenu>
    <div class="content">
      <div style="margin-bottom: 40px">
        <div style="display: flex; align-items: center; margin-bottom: 20px">
          <span style="font-size: 24px; font-weight: 500; margin-right: 17px">服务手册</span>
          <img style="width: 26px; height: 24px" src="../../src/assets/image/title_right.png" alt="" />
        </div>
        <ul class="file-list">
          <li>
            <a
              class="down-link"
              href="https://ch-file.oss-cn-zhangjiakou.aliyuncs.com/temp/toucuju/%E5%B1%95%E5%95%86%E6%9C%8D%E5%8A%A1%E6%89%8B%E5%86%8C.pdf"
            >
              展商服务手册
            </a>
          </li>
          <li>
            <a
              class="down-link"
              href="https://ch-file.oss-cn-zhangjiakou.aliyuncs.com/temp/toucuju/%E5%B1%B1%E8%A5%BF%E7%BB%BC%E6%94%B9%E7%A4%BA%E8%8C%83%E5%8C%BA%E5%B8%82%E5%9C%BA%E7%9B%91%E7%9D%A3%E7%AE%A1%E7%90%86%E5%B1%802024%E4%B8%AD%E5%9B%BD%EF%BC%88%E5%A4%AA%E5%8E%9F%EF%BC%89%E5%9B%BD%E9%99%85%E8%83%BD%E6%BA%90%E4%BA%A7%E4%B8%9A%E5%8D%9A%E8%A7%88%E4%BC%9A%E7%9F%A5%E8%AF%86%E4%BA%A7%E6%9D%83%E9%A3%8E%E9%99%A9%E6%8F%90%E7%A4%BA%E5%87%BD.pdf"
            >
              2024 中国(太原)国际能源产业博览会知识产权风险提示函
            </a>
          </li>
          <li>
            <a
              class="down-link"
              href="https://ch-file.oss-cn-zhangjiakou.aliyuncs.com/temp/toucuju/2024%E4%B8%AD%E5%9B%BD(%E5%A4%AA%E5%8E%9F)%E5%9B%BD%E9%99%85%E8%83%BD%E6%BA%90%E4%BA%A7%E4%B8%9A%E5%8D%9A%E8%A7%88%E4%BC%9A%E4%BC%9A%E5%88%8A.pdf"
            >
              2024中国(太原)国际能源产业博览会会刊
            </a>
          </li>
        </ul>
       
      </div>
      <img class="wen1" src="../../src/assets/image/wen1.png" alt="" />
      <img class="wen2" src="../../src/assets/image/wen1.png" alt="" />
      <div class="rich-ctn">
        <div style="font-size: 24px; line-height: 60px" v-html="content"></div>
        <div style="margin: 25px 0 60px; width: 100%; height: 600px">
          <div id="container"></div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
import topMenu from '@/components/topMenu.vue'
import Footer from '../components/Footer.vue'
import { contentDetail } from '../api/index'
import config from '@/config/config'

export default {
  components: {
    topMenu,
    Footer,
  },
  data() {
    return {
      state: null,
      id: '',
      content: '',
      subtitle: '',
      map: null,
    }
  },
  methods: {
    //页面文本数据
    getInfo(id) {
      contentDetail(id).then(res => {
        // console.log("res", res);
        this.content = res.data.content
        this.subtitle = res.data.news
      })
    },
    // 地图
    initMap() {
      AMapLoader.load({
        key: 'c0cbc925d13acb841241f43e2515abbc', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      })
        .then(AMap => {
          this.map = new AMap.Map('container', {
            //设置地图容器id
            viewMode: '3D', //是否为3D地图模式
            zoom: 16, //初始化地图级别
            center: config.addressCoord, //初始化地图中心点位置
          })

          var marker = new AMap.Marker({
            position: config.addressCoord,
            anchor: 'bottom-center',
          })

          // 将创建的点标记添加到已有的地图实例：
          this.map.add(marker)
        })
        .catch(e => {
          console.log(e)
        })
    },
  },
  mounted() {
    if (this.$route.query) {
      this.id = this.$route.query.section
      this.getInfo(this.id)
      this.initMap()
    }
  },
}
</script>

<style lang="less" scoped>
#container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
}

.content {
  width: 100%;
  position: relative;
  // margin: 20px auto 50px;
  padding: 5.55vh 18.75vw;
  box-sizing: border-box;
  .wen1 {
    position: absolute;
    top: 0;
    right: 0;
    width: 27.6vw;
    height: 37.78vh;
    pointer-events: none;
  }
  .wen2 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 31.77vw;
    height: 37.68vh;
    pointer-events: none;
  }
  .rich-ctn {
    position: relative;
  }
}

.file-list {
  li {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    .down-link {
      color: #88829f;
      font-size: 18px;
      text-decoration: underline;
    }
  }
}


</style>
